/* Poppin */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
/* Garet */
@import url("https://fonts.cdnfonts.com/css/garet");
.containers {
  position: relative;
}

.detail-box {
  display: flex;
  align-items: center;
}
.detail-box .detail-input-icon {
  background-color: #4d3f3f;
  color: #fff;
  font-size: 30px;
  border-radius: 50%;
  padding: 5px;
  margin-right: 20px;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.4);
}
.detail-box .cleander {
  background-color: #6ec531;
  box-shadow: none;
}

.detail-box-content {
  margin-left: 10px;
}
.detail-box-content p {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
  margin-bottom: 5px;
}
.detail-box-content span {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}

.btn-update {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background-color: #fff;
  border: none;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  padding: 10px;
  border-radius: 5px;
  margin-right: 50px;
  margin-top: 10px;
  cursor: pointer;
}

.btn-update span {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.update-icon {
  background-color: #4d3f3f;
  color: #fff;
  font-size: 25px;
  border-radius: 50%;
  padding: 2px;
  margin-left: 20px;
}/*# sourceMappingURL=detailbox.css.map */